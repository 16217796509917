import React, { FC, useState } from "react";
import { Link, navigate } from "gatsby";
import validate from "validate.js";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import InvalidFormInput from "../components/InvalidFormInput";
import { Helmet } from "react-helmet";

const title = "Creează un cont nou";

type FormDataType = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
};

const Register: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState<FormDataType>({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const isAuthenticated = false;

    const handleSetState = (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleRegister = async () => {
        setIsLoading(true);

        // firebaseAuth.createUserWithEmailAndPassword(state.email, state.password)
        //     .then((userCredential: firebase.auth.UserCredential) => {
        //         return userCredential.user.updateProfile({
        //             displayName: state.name,
        //         });
        //     })
        //     .then(() => {
        //         return firebaseAuth.signInWithEmailAndPassword(state.email, state.password);
        //     })
        //     .then(() => {
        //         dispatch(addToast("success", "Userul a fost creat cu succes!"));
        //         navigate("/profile");
        //     })
        //     .catch(error => {
        //         dispatch(addToast("danger", `A apărut o eroare! ${error.message}`));
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
    };

    const formValidation = validate({ name: state.name, email: state.email, password: state.password, confirmPassword: state.confirmPassword }, {
        name: {
            presence: { allowEmpty: false },
            length: { minimum: 3, maximum: 150 },
        },
        email: {
            presence: { allowEmpty: false },
            email: true,
        },
        password: {
            presence: { allowEmpty: false },
            length: { minimum: 6, maximum: 30 },
        },
        confirmPassword: {
            equality: "password",
        },
    });

    if (isAuthenticated) {
        navigate("/profile");
        return null;
    }

    return (
        <Layout isLoading={isLoading}>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="form-area">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="name" className="required">Numele tău</label>
                                            <input type="text" name="name" id="name" placeholder="Nume..." value={state.name} onChange={handleSetState("name")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="name" fieldValue={state.name} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="email" className="required">Email</label>
                                            <input type="email" name="email" id="email" placeholder="Email..." value={state.email} onChange={handleSetState("email")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="email" fieldValue={state.email} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="password" className="required">Parolă</label>
                                            <input type="password" name="password" id="password" placeholder="Parolă..." value={state.password} onChange={handleSetState("password")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="password" fieldValue={state.password} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="confirmPassword" className="required">Confirmă parola</label>
                                            <input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirmă parola..." value={state.confirmPassword} onChange={handleSetState("confirmPassword")} className="form-control" required />
                                            <InvalidFormInput formValidation={formValidation} fieldName="confirmPassword" fieldValue={state.confirmPassword} />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-5">
                                    <button className="btn btn-primary" onClick={handleRegister} disabled={true || isLoading || formValidation != null}>Creează cont</button>
                                </div>
                            </div>
                            <hr />
                            <p className="small text-center">Ai deja cont? <Link to="/login">Autentifică-te</Link></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    );
};

export default Register;
